<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-v-component
      heading="OpenAI Beta"
    />
    <template v-if="error">
      <v-banner
        single-line
        elevation="10"
      >
        <v-icon
          slot="icon"
          color="warning"
          size="36"
        >
          mdi-wifi-strength-alert-outline
        </v-icon>
        <div>
          There was a problem communicating to the server!
          <div class="code">
            <strong>
              {{ error }}
            </strong>
          </div>
        </div>
      </v-banner>
    </template>
    <template v-if="uploading">
      <v-progress-linear
        v-model="progress"
        color="light-blue"
        height="25"
        reactive
        striped
      >
        <strong>{{ progress }} %</strong>
      </v-progress-linear>
    </template>

    <v-row dense>
      <v-col>
        <v-text-field
          v-model="apiKey"
          label="Your OpenAI API Key"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-text-field
          v-model="vin"
          label="VIN"
        />
      </v-col>
    </v-row> 
    <v-row dense>
      <v-col>
        <v-text-field
          v-model="mileage"
          label="Mileage"
        />
      </v-col>
    </v-row>     
    <v-row dense>
      <v-col>
        <v-checkbox
          v-model="used"
        >
          <template v-slot:label>
            <span class="text-no-wrap">Used?</span>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>    
    <v-row dense>
      <v-col class="text-right">
        <v-btn
          color="primary"
          :disabled="uploading || !apiKey || !vin"
          @click="getDescription()"
        >
          Generate Description
        </v-btn>    
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-textarea
          v-model="description"
          label="Vehicle Description"
          outlined
          readonly
          rows="20"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-textarea
          v-model="prompt"
          label="Prompt that was used to generate the description"
          outlined
          readonly
          rows="20"
        />
      </v-col>
    </v-row>

    
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Generating Description
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import { navigationMixin } from '@/mixins/navigationMixin.js'
  import axios from 'axios'
  export default {
    name: 'OpenAi',
    components: {
    },
    filters: {
    },
    mixins: [formatterMixin, navigationMixin],
    data () {
      return {
        description: '',
        prompt: '',
        dialog: false,
        loading: false,
        error: '',
        uploading: false,
        apiKey: '',
        mileage: '',
        vin: '',
        used: false,
      }
    },
    computed: {
      ...mapGetters(['accountId','vehicleDescription']),
    },
    watch: {
      accountId () {
      },
      vehicleDescription () {
        this.description = this.vehicleDescription.description
        this.prompt = this.vehicleDescription.prompt
        this.loading = false
      },
    },
    created () {
    },
    methods: {
      getDescription: function () {
        this.loading = true
        this.description = ''

        axios.get('/api/v1/ai/descriptions?api_key=' + this.apiKey + '&vin=' + this.vin + '&mileage=' + this.mileage + '&used=' + this.used)
          .then(response => {
            // console.log(response.data.description)
            // this.description = response.data.description
            // this.loading = false
            
          })
          .catch(error => {
            this.loading = false
            this.descripton = "Network error. ChatGPT is probably at-capacity. Try again later."
          })
      },
      copyToClipboard (item) {
        this.$copyText(item)
      },      
    },
  }
</script>
